import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Location from "./pages/location";
import Contact from "./pages/contact";
import Building from "./pages/building";


const App: React.FC = () => {
    const routes = [
      { path: "/", component: Home },
      { path: "/building", component: Building },
      { path: "/location", component: Location },
      { path: "/contact", component: Contact }
    ];

    return (
        <>
            <BrowserRouter>
                <Routes>
                    {routes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={<route.component />}
                            />
                        );
                    })}
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;
