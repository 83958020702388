import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import "../nav.scss";
import { duration } from "@mui/material";
import VideoComponent from "../../video/background-video";

const BurgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const btnone = {
        open: {
            rotate: [0, 0, 0, 0, 45],
            translateY: [2, 4, 4, 4, 4 ],
            duration: 0.5,
        },
        closed: {
            rotate: 0,
            translateY: 0,
        },
    };

    const btnthree = {
        open: {
            rotate: [0, 0, 0, 0, -45],
            translateY: [-1, -3, -3, -3, -3 ],
        },
        closed: {
            translateY: 0,
            rotate: 0,
    
        },
    };
    return (
        <div>
            <a
                style={{
                    position: "absolute",
                    zIndex: "52",
                    top: "0",
                    right: "50px",
                    color: "black",
                    cursor: "pointer",
                }}
                onClick={toggleMenu}
            >
                <motion.div
                    className='openbtn'
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <motion.div
                        animate={isOpen ? "open" : "closed"}
                        variants={btnone}
                        initial={false}
                        className='burger-line'
                    />
                    <motion.div
                        animate={isOpen ? "open" : "closed"}
                        variants={btnthree}
                        initial={false}
                        className='burger-line'
                    />
                </motion.div>
            </a>

            {isOpen && (
                <motion.div
                    initial={{ opacity: 0, x: "100vw", display: 'none' }}
                    animate={{ opacity: 1, x: 0, display: 'block'}}
                    exit={{ opacity: 0, x: "100vw" }}
                    transition={{
                        type: "spring",
                        stiffness: 250,
                        damping: 30,
                        duration: 0.25,
                    }}
                    className='burger-menu'
                    style={{
                        width: "100vw",
                        position: "fixed",
                        top: "0",
                        left: "0",
                        height: "100vh",
                        zIndex: "51",
                    }}
                >                <VideoComponent />

                    <div className='links'>
                        <motion.div
                            initial={{ x: -100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 150,
                                damping: 20,
                                delay: 0.5,
                               
                            }}
                        >
                            <Link onClick={() => setIsOpen(false)} to='/'>Home</Link>
                        </motion.div>
                        <motion.div
                            initial={{ x: 100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 150,
                                damping: 20,
                                delay: 0.75,
                            
                            }}
                        >
                            <Link onClick={() => setIsOpen(false)} to='/building'>Building</Link>
                        </motion.div>
                        <motion.div
                            initial={{ x: -100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 150,
                                damping: 20,
                                delay: 1,
                            }}
                       
                        >
                            <Link onClick={() => setIsOpen(false)} to='/location'>Location</Link>
                        </motion.div>
                        <motion.div
                            initial={{ x: 100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 150,
                                damping: 20,
                                delay: 1.25,
                            }}
                        >
                            <Link onClick={() => setIsOpen(false)} to='/contact'>Contact</Link>
                        </motion.div>
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default BurgerMenu;
