import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"; // Add this line to import navigation styles
import "../swiper.scss";

interface Row {
    cells: string[];
}

interface Table {
    floor: string;
    rows: Row[];
}

interface Slide {
    src: string;
    table: Table;
}

interface SwiperProps {
    slides: Slide[];
}

const FloorplanSwiper: React.FC<SwiperProps> = ({ slides }) => {
    const isMobileOrTablet = window.innerWidth <= 1024; // Adjust the width as per your requirement

    return (
        <>
            <SwiperComponent
                spaceBetween={0}
                slidesPerView={1}
                id='floorplanSwiper'
                pagination={{ clickable: true }}
                navigation={true}
                modules={[Pagination, Navigation]}
                centeredSlides={true}
                autoHeight={true}

            >
                {slides.map((slide, index) => (
                    <SwiperSlide key={index}>
                        <div className='img-wrapper'>
                            <img src={slide.src} alt={`slide ${index + 1}`} />
                            {(isMobileOrTablet || index !== 3) ? (
                                <div className={`table ${index > 0 ? " two-col" : ""}`}>
                                    <div className='flex-container'>
                                        {slide.table.rows.map((row, index) => (
                                            <div className='flex-row' key={index}>
                                                {row.cells.map((cell, index) => (
                                                    <div className='flex-cell' key={index}>
                                                        <div>{cell}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className="two-table-wrapper">
                                    {[0, 1].map((tableIndex) => (
                                        <div
                                            className={`table ${
                                                index > 0 ? " two-tables" : ""
                                            }`}
                                            key={tableIndex}
                                        >
                                            <div className='flex-container'>
                                                {slide.table.rows
                                                    .slice(
                                                        tableIndex * Math.ceil(slide.table.rows.length / 2),
                                                        ((tableIndex + 1) * Math.ceil(slide.table.rows.length / 2))
                                                    )
                                                    .map((row, index) => (
                                                        <div className='flex-row' key={index}>
                                                            {row.cells.map((cell, index) => (
                                                                <div className='flex-cell' key={index}>
                                                                    <div>{cell}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </SwiperSlide>
                ))}
            </SwiperComponent>
        </>
    );
};

export default FloorplanSwiper;
