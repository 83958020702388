export const Swipers = [
    {
        src: "../images/building/gallery/1.png",
        table: {
            floor: "Third Floor",
            rows: [
                {
                    cells: ["third floor", "sq ft", "sq m"],
                },
                {
                    cells: ["Single tenant", "29,050", "2,699"],
                },
                {
                    cells: ["Split A", "13,400", "1,245"],
                },
                {
                    cells: ["Split B", "15,650", "1,454"],
                },
            ],
        },
    },
    {
        src: "../images/building/gallery/2.png",
        table: {
            floor: "Third Floor",
            rows: [
                {
                    cells: ["split a", "13,400 sq ft"],
                },
                {
                    cells: ["2p Focus room", "3"],
                },
                {
                    cells: ["8p Meeting room", "2"],
                },
                {
                    cells: ["14p Boardroom", "2"],
                },
                {
                    cells: ["Collaboration zone", "1"],
                },
                {
                    cells: ["Cooms", "1"],
                },
                {
                    cells: ["Flexible meeting room", "1"],
                },
                {
                    cells: ["Focus booths", "1"],
                },
                {
                    cells: ["Hot office", "2"],
                },
                {
                    cells: ["Open plan desks", "90"],
                },
                {
                    cells: ["Reception and waiting area", "1"],
                },
                {
                    cells: ["Store", "3"],
                },
                {
                    cells: ["Teapoint and breakout", "1"],
                },
            ],
        },
    },
    {
        src: "../images/building/gallery/3.png",
        table: {
            floor: "Third Floor",
            rows: [
                {
                    cells: ["split b", "15,650 sq ft"],
                },
                {
                    cells: ["6p Meeting room", "1"],
                },
                {
                    cells: ["Client lounge", "1"],
                },
                {
                    cells: ["Comms", "2"],
                },
                {
                    cells: ["Copy / print", "1"],
                },
                {
                    cells: ["Flexible meeting room", "2"],
                },
                {
                    cells: ["Focus booths", "3"],
                },
                {
                    cells: ["Focus room", "4"],
                },
                {
                    cells: ["Informal meeting room", "3"],
                },
                {
                    cells: ["Open plan desks", "114"],
                },
                {
                    cells: ["Project room", "1"],
                },
                {
                    cells: ["Team hddle", "1"],
                },
                {
                    cells: ["Reception and waiting area", "1"],
                },
                {
                    cells: ["Store", "2"],
                },
                {
                    cells: ["Teapoints and breakout", "2"],
                },
            ],
        },
    },
    {
        src: "../images/building/gallery/4.png",
        table: {
            floor: "Third Floor",
            rows: [
                {
                    cells: ["FULL FLOOR", "29,050 SQ FT"],
                },
                {
                    cells: ["2p Focus room", "2"],
                },
                {
                    cells: ["2p Meeting pod", "2"],
                },
                {
                    cells: ["4p Meeting booths", "1"],
                },
                {
                    cells: ["4p Meeting room", "2"],
                },
                {
                    cells: ["6p Meeting room", "4"],
                },
                {
                    cells: ["14p Boardroom", "2"],
                },
                {
                    cells: ["Comms", "2"],
                },
                {
                    cells: ["Copy / print", "3"],
                },
                {
                    cells: ["Flexible meeting room", "2"],
                },
                {
                    cells: ["Focus booths", "3"],
                },
                {
                    cells: ["Focus room", "5"],
                },
                {
                    cells: ["Hot office", "2"],
                },
                {
                    cells: ["Informal meeting room", "5"],
                },
                {
                    cells: ["Team huddle", "2"],
                },
                {
                    cells: ["Open plan desks", "162"],
                },
                {
                    cells: ["Town hall", "1"],
                },
                {
                    cells: ["Post room", "1"],
                },
                {
                    cells: ["First aid / Mothers room", "1"],
                },
                {
                    cells: ["Prayer room", "1"],
                },
                {
                    cells: ["Reception and waiting area", "1"],
                },
                {
                    cells: ["Store", "3"],
                },
                {
                    cells: ["Teapoints", "2"],
                },
                {
                    cells: ["Breakout", "1"],
                },
            ],
        },
    },
];

export const spec = [
    {
        src: "../images/building/spec/1.svg",
        caption: "Cafe / amenity offering",
    },
    {
        src: "../images/building/spec/2.svg",
        caption: "5 DDA compliant passenger lifts and goods hoist",
    },
    {
        src: "../images/building/spec/3.svg",
        caption: "VAV heating and cooling system",
    },
    {
        src: "../images/building/spec/4.svg",
        caption: "New CAT A fit out to suit tenants' requirements",
    },
    {
        src: "../images/building/spec/5.svg",
        caption:
            "Quadruple height entrance foyer and reception space with a feature staircase",
    },
    {
        src: "../images/building/spec/6.svg",
        caption: "Anticipated EPC B rating",
    },
    {
        src: "../images/building/spec/7.svg",
        caption: "Ground floor shower and changing facilities",
    },
    {
        src: "../images/building/spec/8.svg",
        caption:
            "92 car parking spaces for 3rd floor, providing an attractive ratio of 1:338",
    },
];
