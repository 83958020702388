import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import './spec.scss';

interface Props {
        images: { src: string; caption: string }[];
        direction?: string;
}

const SpecComponents: React.FC<Props> = ({ images, direction }) => {

        const [isVisible, setIsVisible] = useState(false);
        const ref = useRef(null)
        const isInView = useInView(ref, { once: true })
        const controls = useAnimation();
    
        useEffect(() => {
            const startAnimation = async () => {
                if (isInView && !isVisible) {
                    setIsVisible(true);
                    await controls.start((index) => ({
                        x: 0,
                        opacity: 1,
                        transition: { type: 'spring', stiffness: 150, damping: 20, duration: 0.5, delay: index * 0.25 }
                    }));
                } else if (!isInView && isVisible) {
                    setIsVisible(false);
                    await controls.start({ opacity: 0 });
                }
            };
            startAnimation();
        }, [isInView, isVisible, controls]);


        return (
                <div className={`icons-wrap ${direction}`}>
                        {images.map((image, index) => (
                                <motion.div
                                        ref={ref}
                                        key={index}
                                        animate={controls}
                                        initial={{ opacity: 0, x: '-10%' }}
                                        custom={index}
                                >
                                        <img src={image.src} alt={image.caption.split(' ')[0]} />
                                        <p>{image.caption}</p>
                                </motion.div>
                        ))}
                </div>
        );
};

export default SpecComponents;
