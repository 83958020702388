import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"; // Import navigation styles
import "../swiper.scss";

interface Slide {
    src: string;
}

interface SwiperProps {
    slides: Slide[];
}

const Swiper: React.FC<SwiperProps> = ({ slides }) => {
    return (
        <>
            <SwiperComponent
                spaceBetween={50}
                slidesPerView={1}
                id='swiper'
                pagination={{ clickable: true }}
                modules={[Pagination, Navigation]} // Add Navigation module
                navigation={true} // Enable navigation
                loop={true}
                centeredSlides={false}
                initialSlide={2}
                autoplay={{ delay: 3000 }} // Add autoplay with a delay of 3000ms (3 seconds)
                breakpoints={{
                    789: {
                        slidesPerView: 2,
                    }
                }}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide style={{width: '48%', display: 'flex', 'flexDirection': 'column'}} key={index}>
                        <img src={slide.src} alt={`slide ${index + 1}`} />
                    </SwiperSlide>
                ))}
            </SwiperComponent>
        </>
    );
};

export default Swiper;
