import VideoComponent from "../video/background-video";
import "./footer.scss";

interface Agent {
  name: string;
  phone: string;
  email: string;
}

const savills: Agent[] = [
  {
    name: "Izzy Vyvyan",
    phone: "07977 757382",
    email: "izzy.vyvyan@savills.com",
  },
  {
    name: "Mike Storrs",
    phone: "01245 293228",
    email: "mstorrs@savills.com",
  },
];
const dww: Agent[] = [
  {
    name: "Simon Beeton",
    phone: "07976 523373",
    email: "scb@dww.co.uk",
  },
  {
    name: "Elizabeth Finch",
    phone: "01279 620200",
    email: "ef@dww.co.uk",
  },
];

interface footerProps {
  buldingPage?: boolean;
}

const Footer: React.FC<footerProps> = ({ buldingPage = false }) => {
  return (
    <>
      <div className="contain-wrap">
        <VideoComponent />
        {buldingPage ? (
          <div className="contain-wrap">
            <div className="contain">
              <img src="../images/building/2.png" width={"100%"} />
            </div>
          </div>
        ) : (
          <img
            src="../images/triangle.svg"
            className="triangle-flipped"
            style={{ marginTop: "-1px" }}
          />
        )}
        <div className="contain" style={{ width: "min(1000px, 100%" }}>
          <div className="footer-hero">
            <h5>Please contact the agents for further information:</h5>

            <div className="footer-wrapper">
              <div className="agents-wrap">
                <div className="agents-wrapper">
                  <img src="../images/savills.svg" />
                  {savills.map((agent, index) => (
                    <div className="agents" key={index}>
                      <p>
                        <b>{agent.name}</b>
                      </p>
                      <p>{agent.phone}</p>
                      <p>
                        <a href={`mailto:${agent.email}`}>{agent.email}</a>
                      </p>
                    </div>
                  ))}
                </div>
                <div className="agents-wrapper">
                  <img src="../images/dww.svg" />
                  {dww.map((agent, index) => (
                    <div className="agents" key={index}>
                      <p>
                        <b>{agent.name}</b>
                      </p>
                      <p>{agent.phone}</p>
                      <p>
                        <a href={`mailto:${agent.email}`}>{agent.email}</a>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="footer-btn">
                {/* <a href="https://kaopark.com" className="btn">
                  <p>VISIT KAO PARK</p>
                </a> */}
                <a href="../dl/brochure.pdf" target="_blank" className="btn">
                  <p>BROCHURE</p>
                </a>
              </div>
            </div>
            <div className="misrep">
              <p>
                Savills, Derrick Wade Waters and their clients and any joint
                agents give notice that: 1. They are not authorised to make or
                give any representations or warranties in relation to the
                property either here or elsewhere, either on their own behalf or
                on behalf of their client or otherwise. They assume no
                responsibility for any statement that may be made in these
                particulars. These particulars do not form part of any offer or
                contract and must not be relied upon as statements or
                representations of fact. 2. Any areas, measurements or distances
                are approximate. The text, images and plans are for guidance
                only and are not necessarily comprehensive. It should not be
                assumed that the property has all necessary planning, building
                regulation or other consents and Savills have not tested any
                services, equipment or facilities. Purchasers must satisfy
                themselves by inspection or otherwise. February 2024.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
