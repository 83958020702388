import { Link, useLocation } from "react-router-dom";
import "./nav.scss";
import { motion } from "framer-motion";
import BurgerMenu from "./burger/burger";

const renderAnimation = () => {
  return (
    <>
      <motion.div
        className="nav-bar"
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{
          type: "spring",
          stiffness: 200,
          damping: 30,
          delay: 4,
          duration: 0.5,
        }}
      >
        <div className="nav-content">
          <div className="nav-side">
            <Link to={"/"} className="logo">
              <img src="../logo.svg" alt="logo" />
            </Link>
          </div>
          <div className="nav-side">
            <>
              <Link className="nav-link" to="/building">
                <motion.h6
                  initial={{ x: 50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 150,
                    damping: 30,
                    delay: 5,
                    duration: 0.5,
                  }}
                >
                  building
                </motion.h6>
              </Link>
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: "50px" }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 30,
                  delay: 7,
                  duration: 0.5,
                }}
              >
                <hr className="line" />
              </motion.div>
              <Link className="nav-link" to="/location">
                <motion.h6
                  initial={{ x: 50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 150,
                    damping: 30,
                    delay: 5.5,
                    duration: 0.5,
                  }}
                >
                  location
                </motion.h6>
              </Link>
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: "50px" }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 30,
                  delay: 7,
                  duration: 0.5,
                }}
              >
                <hr className="line" />
              </motion.div>
              <Link className="nav-link" to="/contact">
                <motion.h6
                  initial={{ x: 50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 150,
                    damping: 30,
                    delay: 6,
                    duration: 0.25,
                  }}
                >
                  contact
                </motion.h6>
              </Link>
            </>
          </div>
        </div>
        <div className="burger">
          <BurgerMenu />
        </div>
      </motion.div>
    </>
  );
};

const renderNav = (active: string) => {
return (
    <div className="nav-bar">
        <div className="nav-content">
            <div className="nav-side">
                <Link to={"/"}>
                    <img src="../logo.svg" alt="logo" />
                </Link>
            </div>
            <div className="nav-side">
                <>
                    <Link
                        className={`nav-link ${active === "/building" ? "active" : ""}`}
                        to="/building"
                    >
                        <h6>building</h6>
                    </Link>
                    <motion.div
                        initial={{ width: 0 }}
                        animate={{ width: "50px" }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 20,
                            duration: 0.25,
                        }}
                    >
                        <hr className="line" />
                    </motion.div>
                    <Link
                        className={`nav-link ${active === "/location" ? "active" : ""}`}
                        to="/location"
                    >
                        <h6>location</h6>
                    </Link>
                    <motion.div
                        initial={{ width: 0 }}
                        animate={{ width: "50px" }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 20,
                            duration: 0.25,
                        }}
                    >
                        <hr className="line" />
                    </motion.div>
                    <Link
                        className={`nav-link ${active === "/contact" ? "active" : ""}`}
                        to="/contact"
                    >
                        <h6>contact</h6>
                    </Link>
                </>
            </div>
            <div className="burger">
                <BurgerMenu />
            </div>
        </div>
    </div>
);
};

const Nav: React.FC = () => {
  const location = useLocation();

  if (location.pathname === "/") {
    return renderAnimation();
  } else {
    console.log(location.pathname);
    return renderNav(location.pathname);
  }
};

export default Nav;
