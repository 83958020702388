import { useInView, useAnimation, motion } from "framer-motion";
import { useState, useRef, useEffect } from "react";
import "./travel.scss";

interface ImageComponent {
  title: string;
  images: { src: string; caption: { place: string; distance: string } }[];
  direction?: string;
}

const TravelComponent: React.FC<ImageComponent> = ({
  images,
  title,
  direction,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    const startAnimation = async () => {
      if (isInView && !isVisible) {
        setIsVisible(true);
        await controls.start((index) => ({
          x: 0,
          opacity: 1,
          transition: {
            type: "spring",
            stiffness: 200,
            damping: 20,
            delay: index * 0.25,
          },
        }));
      } else if (!isInView && isVisible) {
        setIsVisible(false);
        await controls.start({ opacity: 0 });
      }
    };
    startAnimation();
  }, [isInView, isVisible, controls]);

  return (
    <>
      <div className={`travel-wrap ${direction}`}>
        <h2>{title}</h2>
        <div className="gradient-border"></div>
        <div className="content">
          {images.map((image, index) => (
            <motion.div
              ref={ref}
              key={index}
              animate={controls}
              initial={{ opacity: 0, x: "-10%" }}
              custom={index}
              className="icons"
            >
              <img src={image.src} alt={image.caption.place.split(" ")[0]} />
              <p>{image.caption.place}</p>
              <p>
                <b>{image.caption.distance}</b>
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TravelComponent;
