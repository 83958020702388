export const specImages = [
    {
        src: "../images/location/spec/1.svg",
        caption:
            "1 in 5 professionals in the corridor work in the knowledge economy",
    },
    {
        src: "../images/location/spec/2.svg",
        caption: "12.5% greater productivity than the UK average",
    },
    {
        src: "../images/location/spec/3.svg",
        caption:
            "Over 2.1 million jobs in the corridor fosters highly skilled roles",
    },
    {
        src: "../images/location/spec/4.svg",
        caption:
            "37 life sciences research institutes, and 1,400 life sciences businesses",
    },
];

export const travelProps = {
    title: "Air",
    images: [
        {
            src: "../images/location/travel/1.svg",
            caption: {
                place: "London Stansted",
                distance: "13 miles",
            },
        },
        {
            src: "../images/location/travel/1.svg",
            caption: {
                place: "London city",
                distance: "22 miles",
            },
        },
        {
            src: "../images/location/travel/1.svg",
            caption: {
                place: "heathrow",
                distance: "41 miles",
            },
        },
    ],
};
export const travelProps2 = {
    title: "Rail",
    images: [
        {
            src: "../images/location/travel/2.svg",
            caption: {
                place: "tottenham hale",
                distance: "17 mins",
            },
        },
        {
            src: "../images/location/travel/2.svg",
            caption: {
                place: "stansted airport",
                distance: "17 mins",
            },
        },
        {
            src: "../images/location/travel/2.svg",
            caption: {
                place: "london liverpool street",
                distance: "27 mins",
            },
        },
        {
            src: "../images/location/travel/2.svg",
            caption: {
                place: "cambridge",
                distance: "41 mins",
            },
        },
    ],
};
export const travelProps3 = {
    title: "Road",
    images: [
        {
            src: "../images/location/travel/3.svg",
            caption: {
                place: "harlow mill station",
                distance: "2.5 miles",
            },
        },
        {
            src: "../images/location/travel/3.svg",
            caption: {
                place: "m25 (j7)",
                distance: "2.5 miles",
            },
        },
        {
            src: "../images/location/travel/3.svg",
            caption: {
                place: "harlow station",
                distance: "3.3 miles",
            },
        },
        {
            src: "../images/location/travel/3.svg",
            caption: {
                place: "m25 (j27)",
                distance: "13 miles",
            },
        },
        {
            src: "../images/location/travel/3.svg",
            caption: {
                place: "cambridge",
                distance: "20 miles",
            },
        },
        {
            src: "../images/location/travel/3.svg",
            caption: {
                place: "chelmsford",
                distance: "21 miles",
            },
        },
        {
            src: "../images/location/travel/3.svg",
            caption: {
                place: "london",
                distance: "26 miles",
            },
        },
    ],
};
